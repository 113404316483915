.navbar{
    padding: 20px;
    z-index: 999;

    .top{
        padding: 0px 70px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 25px;
        margin: 20px;
        color: #fff;

        .logo{
            display: flex;
            align-items: center;
            gap: 10px;

            .orgLogo{

                img{
                    width: 70px;
                    height: auto;
                }
            }
            .orgName{
                .logoName{
                    font-size: 30px;
                    font-family: Roboto;
                    letter-spacing: 1px;
                    font-weight: bolder;
                }
                .slug{
                    text-transform: uppercase;
                    font-weight: bolder;
                    font-size: 14px;
                }
            }
        }

    }
    .menuBar{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 20px 30px;
        background-color: rgb(255, 255, 255, 0.2);
        font-family: "Comfortaa";
        padding: 0;

        ul{
            padding: 0px;
            margin: 0px;
        }
        .active{
            background-color: red;
        }
       .nav-link{
            padding: 0;
            display: flex;
            li{
                list-style: none;
                font-family: Roboto;
                padding: 0px 20px;
                cursor: pointer;
                text-transform: uppercase;
                font-size: 13px;
                letter-spacing: 2px;
                padding: 20px;
                color: rgb(255, 255, 255);
            }
            li:hover{
                background-color: rgb(243, 56, 9);
                padding: 20px;
                transition: 0.5 ease-in-out;
            }
        }
        .getNot{
            background-color: rgb(12, 2, 104);
            color: #fff;
            font-family: Roboto;
            letter-spacing: 2px;
            text-transform: uppercase;
            padding: 20px 70px;
            font-size: 14px;
            cursor: pointer;
            clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
        }
        .link-mobile{
            display: none;
        }
    }
}