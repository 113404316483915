.about{
    display: flex;
    padding: 0;
    flex-direction: column;

    .contactBanner{
        padding: 0;
        background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.9)), url("../../images/sl3.jpg");
        height: 60vh;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;

        .conTitile{
            padding: 20px 100px;

            h2{
                color: #fff;
                font-family: Roboto;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }

    }
    .aboutBg{
        padding: 50px 100px;
        display: flex;
        gap: 10px;
        flex-direction: column;

        .who{
            display: flex;
            gap: 40px;
            .aboutLeft{
                flex: 1;
                h2{
                    font-size: 20px;
                    font-weight: bold;
                }
                p{
                    line-height: 27px;
                    text-align: justify;
                }
            }
            .aboutRight{
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 30px;

                .aboutTop{
                    padding: 20px;
                    background-color: rgb(12, 2, 104);
                    h3{
                        color:#fff;
                        font-size: 18px;
                    }
                    ul{
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li{
                            color: #fff;
                            padding: 5px 10px;
                            line-height: 25px;
                        }
                    }
                }
                .aboutBootm{
                    padding: 20px;
                    background-color: rgb(243, 56, 9);
                    h3{
                        color:#fff;
                        font-size: 18px;
                    }
                    p{
                        color:#fff;
                        font-weight: bold;
                    }
                    ul{
                        list-style: none;
                        padding: 0;
                        margin: 0;

                        li{
                            color: #fff;
                            padding: 5px 10px;
                            line-height: 25px;
                        }
                    }
                }
            }
            
        }

        .objective{
            display: flex;
            gap: 30px;

            .obLeft{
                flex: 1;
            }
            .obRight{
                flex: 1;
            }
        } 
    }
}