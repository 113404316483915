@media screen and (max-width:900px){
    .register{
        .contactBg{
            padding: 50px 10px;
            .address {
                padding: 0;
                gap: 40px;
                display: grid;
                grid-template-columns: repeat(1, 1fr);
                grid-gap: 10px;

                .elegible {
                    width: 100%;
                }
                
            }
        } 
    } 
}