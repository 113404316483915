@media screen and (max-width:900px){
    .contact{

        .contactBg {
        padding: 50px 20px;
        display: flex;
        gap: 30px;
        flex-direction: column;
        }
        .contactBanner{

            .conTitile {
                padding: 20px 20px;
                h2 {
                    color: #fff;
                    text-transform: uppercase;
                    font-family: Roboto;
                    text-align: center;
                }
            }
        } 
    }
}