.Sub{
    display: flex;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    justify-content: center;
    padding: 50px 10px;
    text-align: center;

    .signup{
        font-size: 20px;
        line-height: 40px;
        font-weight: bold;

        h2{
            font-size: 30px;
            margin: 0;
            padding: 0;
            font-weight: bold;
        }
    }
    .signForm:hover{
        border: 1px solid #e6e5e5;
        border-radius: 30px;
    }
    .signForm{
        display: flex;
        width:max-content;
        border: 1px solid rgb(243, 56, 9);
        padding: 5px 5px;
        align-items: center;
        justify-content: center;
        border-radius: 30px;

        input{
            width: 400px;
            padding: 10px;
            border: 0px;
            border-radius: 5px;
        }
        button{
            border: 0px;
            padding: 10px 20px;
            color: #e6e5e5;
            background-color: rgb(243, 56, 9);
            font-weight: bolder;
            font-size: 18px;
            border-radius: 50px;
        }
    }
}