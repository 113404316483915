.nig{
    display: flex;
    padding: 0;
    flex-direction: column;

    .contactBanner{
        padding: 0;
        background: linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.9)), url("../../images/sl3.jpg");
        height: 60vh;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;

        .conTitile{
            padding: 20px 100px;

            h2{
                color: #fff;
                text-transform: uppercase;
                letter-spacing: 2px;
            }
        }

    }
    .ANig{
            padding: 0px;
            display: flex;
            .iceyLeft{
                width: 50%;
                padding: 50px;
                
                h2{
                    color: rgb(12, 2, 104);
                    letter-spacing: 2px;
                }
                p{
                    font-size: 16px;
                    color: #222222;
                    line-height: 30px;
                    text-align: justify;
                }
            }
            .iceyRight{
                width: 50%;
                padding: 50px;
                background-color: #f8f8f8;
                h2{
                    color: rgb(243, 56, 9);
                    letter-spacing: 2px;
                }
                p{
                    font-size: 16px;
                    color: #222222;
                    line-height: 30px;
                    text-align: justify;
                }
            }
    }
}