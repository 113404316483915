.heritages{
    padding: 0;

    .galleryBox{
        display: flex;
        flex-direction: column;

        .galleryTitle{
            text-align: center;
            font-size: 25px;
            line-height: 40px;
            display: flex;
            flex-direction: column;
            color: rgb(243, 56, 9);
            padding: 50px 100px;
            text-transform: uppercase;
            h1{
            font-family: Anton;
            padding: 0;
            margin: 0;
            font-size: 45px;
            color:rgb(12, 2, 104);
            letter-spacing: 4px;
            }
        }
        .pixcontaner{
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 5px;
            .pic{
                position: relative;
                flex: 1;
                .titlePix{
                font-size: 18px;
                font-weight: bolder;
                position: absolute;
                top: 40%;
                left: 35%;
                color: #fff;
                overflow: hidden;
                }
                img{
                width: 100%;
                height: 400px;
                object-fit: cover;
                cursor: pointer;
                overflow: hidden;
                opacity: .9;
                -webkit-transition: .3s ease-in-out;
                transition: .6s ease-in-out;
                }
                img:hover{
                    opacity: 1;
                    transition: .6s ease-in-out;
                    overflow: hidden;
                }
                
                }
        }
        
    }
}