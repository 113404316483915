.we{
    padding: 0;

    .weTitle{
        text-align: center;
        font-size: 30px;
        line-height: 60px;
        display: flex;
        flex-direction: column;
        color: rgb(243, 56, 9);

        h1{
            font-family: Anton;
            padding: 0;
            margin: 0;
            font-size: 60px;
            color:rgb(12, 2, 104);
            letter-spacing: 2px;
        }
    }
    .cardTitle{
        display: flex;
        justify-content: space-around;
        padding: 50px 0px;
        gap:10px;

        .carDetails:hover{
            color: #ffffff;
            background-color: rgb(243, 56, 9);
            
        }
        .carDetails{
            border: 1px solid rgb(233, 233, 233);
            flex: 1;
            display: flex;
            flex-direction: column;
            padding: 30px;
            color: #111111;

            .cardTop{
                display: flex;
                gap: 10px;

                .cardtopright{
                    font-family: Roboto;
                    line-height: 25px;
                }
            }
            .cardContent{

                p{
                    font-size: 17px;
                    line-height: 30px;
                }
            }
        }
    }

}