@media screen and (max-width:900px){
    .icyeNig .contactBanner .conTitile {
        padding: 20px 20px;
        text-align: center;
    }
    .icyeNig .iceyBg {
        padding: 0px;
    }
    .icyeNig .iceyBg .iceyLeft {
        padding: 20px;
        background-color: #f8f8f8;
    }
    .icyeNig .iceyBg .iceyRight {
        padding: 20px;
    }
}