.admin{
    padding: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    .login{
        width: 350px;
        padding: 20px;
        display: flex;
        flex-direction: column;
        gap:30px;
        .title{
            text-align: center;
            font-weight: bolder;
            font-size: 16px;
            text-transform: uppercase;
        }
        .loginFields{
            display: flex;
            flex-direction: column;
            gap:20px;
            .textBox{
                display: flex;
                align-items: center;
                gap:10px;
                border-radius: 5px;
                border: 1px solid #080580;
                padding: 5px;
                .left{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
                .right{

                    input{
                        width: 100%;
                        border: 0;
                        padding: 10px;
                    }
                }
            }
            .textBox:hover{
                border: 1px solid #dddcdc;
            }
            button{
                padding: 10px;
                border: 0px;
                background-color: #080580;
                color: #fff;
                font-weight: bolder;
                border-radius: 0px;
                font-size: 16px;
                cursor: pointer;
            }
        }
    }
}