@media screen and (max-width:900px){
    .home .coming .titleUp h2 {
        font-family: Anton;
        padding: 0;
        margin: 0;
        font-size: 30px;
        color: rgb(12, 2, 104);
        letter-spacing: 2px;
    }
    .home{
        .comP{
            display: grid;
            grid-template-columns: repeat(1, 1fr);
            gap: 30px;
            }
    }
}