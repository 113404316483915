.register{
    display: flex;
    padding: 0;
    flex-direction: column;

    .contactBanner{
        padding: 0;
        background: linear-gradient(rgba(0,0,0,0.7), rgba(0,0,0,0.9)), url("../../images/sl2.jpg");
        height: 60vh;
        background-repeat: no-repeat;
        background-attachment: fixed;
        background-position: center;
        background-size: cover;

        .conTitile{
            padding: 20px 100px;

            h2{
                color: #fff;
                text-transform: uppercase;
                font-family: Roboto;
            }
        }

    }
    .contactBg{
        padding: 50px 100px;
        display: flex;
        gap: 30px;
        flex-direction: column;

        .address{
            padding: 0;
            display: flex;
            gap: 40px;
            .elegible{
                width: 50%;
                h2{
                    font-size: 16px;
                    font-family: Roboto;
                    letter-spacing: 2px;
                }
                p{
                    line-height: 30px;
                }
                ul{
                    padding: 0px;
                    margin: 0;
                    li{
                        list-style: none;
                    }
                }
            }
        }
        .contactForm{
            padding: 0;
            display: flex;
            flex-direction: column;
            gap: 20px;
            .formBrag{
                display: flex;
                gap: 20px;
                .formLeft{
                    width: 50%;
                    background-color: #f8f8f8;
                    display: flex;
                    gap: 10px;
                    padding: 10px;
                    align-items: center;

                    .iconsField{
                        width: 100%;
                        input{
                            border: 0px;
                            width: 100%;
                            padding: 10px 0px;
                            background-color: inherit;

                            ::placeholder{
                                font-size: 18px;
                            }
                        }
                    }
                }
                .formtextarea{
                    background-color: #f8f8f8;
                    width: 100%;
                    padding: 10px;
                    textarea{
                        background-color: inherit;
                        width: 100%;
                        height: 300px;
                        border: 0px;
                    }
                }
                button{
                    padding: 17px 25px;
                    border-radius: 30px;
                    font-size: 16px;
                    font-weight: bold;
                    border: 0;
                    color: #fff;
                    cursor: pointer;
                    background-color:  rgb(243, 56, 9);;
                }
            }
        }

    }
}