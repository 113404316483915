.container{
    padding: 10px 100px;
    .heroSec{
        display: flex;
        flex-direction: column;
        gap:20px;
        justify-content: center;
        align-items: center;

        .wel{
            font-size: 30px;
            font-weight: bold;
            color: rgb(255, 255, 255);
            font-family: Roboto;
            letter-spacing: 2px;
            text-shadow: 2px 0px 2px rgba(19,18,18,0.6);
        }
        h1{
            font-size: 60px;
            padding: 0px;
            margin: 0px;
            line-height: 60px;
            text-align: center;
            font-family: Roboto;
            letter-spacing: 3px;
            color: #fff;
            text-shadow: 2px 0px 2px rgba(19,18,18,0.6);
        }
        .contactButton{
            display: flex;
            gap:10px;

            button{
                border: 0;
                padding: 15px 50px;
                text-transform: uppercase;
                font-size: 16px;
                border-radius: 50px;
                cursor: pointer;
                font-family: Roboto;
            }
            .vol{
                background-color: rgb(41, 20, 238,0.7);
                color: #fff;
                letter-spacing: 2px;
            }
            .cons{
                background-color: rgb(243, 56, 9, 0.7);
                color: #fff;
                letter-spacing: 2px;
            }
        }
    }
}