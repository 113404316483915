@font-face {
  font-family: "Comfortaa";   /*Can be any text*/
  src: local("Comfortaa-VariableFont_wght"),
  url('../src/fonts/Comfortaa-VariableFont_wght.ttf') format("truetype");
}
@font-face {
  font-family: "Anton";   /*Can be any text*/
  src: local("Anton-Regular"),
  url('../src/fonts/Anton-Regular.ttf') format("truetype");
}
@font-face {
  font-family: "Roboto";   /*Can be any text*/
  src: local("Roboto-Bold"),
  url('../src/fonts/Roboto-Bold.ttf') format("truetype");
}

body {
  margin: 0;
  font-family: "Comfortaa";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
*:focus {
  outline: none;
}
textarea:focus, input:focus{
  outline: none;
}
.links{
  text-decoration: none;
  color: inherit;
}
