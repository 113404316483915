.home{
    padding: 0;
    margin: 0;

    .sliderHero{
        padding: 0;
        margin: 0;
        position: relative;

        img{
            width: 100%;
            height: 100vh;
            object-fit: cover;
        }
    
        .contain{
            position: absolute;
            top: 0;
            padding: 0;
            margin: 0;
            width: 100%;
            z-index: 999;
            background-color: rgb(8, 8, 8,0.5);
            height: 100vh;
        }
    }
    .containerBg{
        display: flex;
        padding: 70px 130px;
        gap: 40px;
        background-color: #f8f8f8;

        .leftBg{
            width: 50%;

            img{
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .rightBg{
            width: 50%;
        }
    }
    .icyeWe{
        padding: 50px 50px;
        margin: 50px;
    }
    .become{
        padding: 0;
    }
    
}