@media screen and (max-width:900px){
    .about .contactBanner .conTitile {
        padding: 20px 20px;
        text-align: center;
    }
    .about .aboutBg {
        padding: 20px 20px;
        display: grid;
        gap: 10px;
        flex-direction: column;
    }
    .about .aboutBg .who {
        display: block;
    }
}