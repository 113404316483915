.coming{
    padding: 100px 70px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    .titleUp{
        text-align: center;
        font-size: 30px;
        line-height: 40px;
        display: flex;
        flex-direction: column;
        color: rgb(243, 56, 9);
        h2{
            padding: 0;
            margin: 0;
            font-size: 30px;
            color:rgb(12, 2, 104);
            letter-spacing: 1px;
            font-weight: bolder;
        }
    }
    .comP{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 30px;

        .active{
            background-color: red;
            color: #fff;
            border: 0px solid #e6e5e5;
            
            h3{
                margin: 0px;
                color: rgb(255, 255, 255);
                font-weight: bolder;
                font-size: 17px;
                text-transform: capitalize;
               }
        }
        
        .comHolder{
            border: 1px solid #e6e5e5;
            padding: 10px;
            flex: 1; 
            display: flex;
            gap: 10px;
            flex-direction: column;
            cursor: pointer;

           h3{
            margin: 0px;
            color: rgb(243, 56, 9);
            font-weight: bolder;
            font-size: 17px;
            text-transform: capitalize;
           }

           img{
            width: 100%;
            height: 150px;
            object-fit: cover;
           }
           p{
            margin: 0;
            padding: 0;
            line-height: 25px;
            font-size: 16px;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 3; /* number of lines to show */
            line-clamp: 3; 
            -webkit-box-orient: vertical;
           }
       }
       .comHolder:hover{
        background-color: #f8f8f8;
       }
    }
}