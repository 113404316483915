@media screen and (max-width:900px){
    .heritages .galleryBox .pixcontaner .pic img {
        width: 100%;
        height: 300px;
        object-fit: cover;
        cursor: pointer;
        overflow: hidden;
        opacity: 0.9;
        transition: 0.3s ease-in-out;
    }
}