.Bcom{
    padding: 0px;
    margin: 0;
    background:linear-gradient(rgba(12, 2, 104, 0.7), rgba(0, 0, 0, 0.7) ), url("../../images/whoweare.png");
    background-repeat: no-repeat;
    background-position:center;
    background-size: cover;
    background-attachment: fixed;
    height: 80vh;
    object-fit: cover;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .textDetail{
        display: flex;
        flex-direction: column;
        text-align: center;
        gap: 30px;

        .txt{
            color: #fff;

            h2{
                font-family: Roboto;
                letter-spacing: 2px;
            }
            
            h1{
                font-family: Roboto;
                font-size: 50px;
                letter-spacing: 2px;
                margin: 0;
                padding: 0;
            }
        }
        .contactButton{
            display: flex;
            gap:10px;
            justify-content: center;

            button{
                border: 0;
                padding: 15px 50px;
                text-transform: uppercase;
                font-size: 16px;
                cursor: pointer;
                border-radius: 50px;
                font-family: Roboto;
                letter-spacing: 2px;
            }
            .vol{
                background-color: rgb(41, 20, 238,0.6);
                color: #fff;
            }
            .cons{
                background-color: rgb(243, 56, 9,0.6);
                color: #fff;
            }
        }
    }
}