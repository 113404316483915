@media screen and (max-width:900px){
    .nig{
        .contactBanner{
            .conTitile {
                padding: 20px 20px;
                text-align: center;
            }
        }
    } 
    .nig{
        .ANig {
            padding: 0px;
        }
    } 
    .nig{
        .ANig{
            .iceyLeft {
                width: 90%;
                padding: 20px;
            }
        }
    }  
    .nig .ANig .iceyRight {
        width: 90%;
        padding: 20px;
        background-color: #f8f8f8;
    }
}