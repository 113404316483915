.who{
    padding: 50px 0px;

    h2{
        font-size: 18px;
        background-color: rgb(243, 56, 9);
        color: #fff;
        width: max-content;
        padding: 5px 30px;
        clip-path: polygon(10% 0, 100% 0, 100% 100%, 0 100%);
    }
    h1{
        font-size: 35px;
        font-family: Roboto;
    }
    .boxFram{
        margin-top: 50px;

        .bxF{
            padding: 20px;
            background-color: #fff;
            font-weight: bold;
            line-height: 30px;
            border-radius: 5px;
            margin-bottom: 20px;

            .titl{
                font-weight: bolder;
            }
        }
        .fbook{
            padding: 0px;
            .fbTitle{
                font-weight: bolder;
                font-size: 25px;
                margin-bottom: 10px;
            }
        }
    }
}