@media screen and (max-width:900px){
   .navbar{

    .top{
        padding: 0px 10px;
    }
    .menuBar {
    margin: 10px 10px;
    position: relative;

    .nav-link {
        display: none;
     }
    
    .getNot{
        cursor: pointer;
        padding: 14px 10px;
        font-size: 10px;
        clip-path: polygon(0% 0, 100% 0, 100% 100%, 0 100%);
    }
    .link-mobile{
        display: block;
        font-size: 20px;
        right: 20px;
        top: 7px;
        color: #f8f8f8;
        background-color:inherit;
        position: absolute;
        border: 0px;
        font-weight: bolder;
        border: 0px;
    }
    .mobile-link{
        position: absolute;
        width: 100%;
        left:0;
        background-color: rgb(0, 0, 0,0.5);
        margin-top: 192px;
        padding: 15px;
        
        ul{
            list-style: none;
            text-decoration: none;
            li{
                padding: 10px;
                list-style: none;
                text-decoration: none;
                padding: 10px;
                border-bottom: 1px solid #f8f8f8;
            }
            
        }
    }
   }
} 
    
}