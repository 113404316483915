.footer{
    padding: 0px;
    display: flex;

    .footerLeft{
        flex: 2;
        background-color: rgb(243, 56, 9);
        padding: 100px 50px;
        .abi{
            flex: 2;
            gap: 1px;
            display: flex;
            flex-direction: column;

            h2{
                font-size: 20px;
                font-weight: bold;
                margin: 0;
                color:#fff;
            }
            p{
                line-height: 25px;
                font-size: 18px;
                color: #f3f2f2;
            }
            .signForm{
                display: flex;
                width:max-content;
                background-color: #fff;
                padding: 5px 5px;
                align-items: center;
                justify-content: center;
                border-radius: 30px;
                margin-top: 30px;

                input{
                    width: 400px;
                    padding: 10px;
                    border: 0px;
                    border-radius: 5px;
                    border-radius: 30px;
                }
                button{
                    border: 0px;
                    padding: 10px 20px;
                    color: rgb(243, 56, 9);
                    background-color: rgb(255, 255, 255);
                    font-size: 14px;
                    border-radius: 50px;
                    font-weight: 600;
                    cursor: pointer;
                    text-transform: uppercase;
                }
            }
        }
    }

    .footerRight{
        flex: 5;
        display: flex;
        gap: 10px;
        padding: 100px 50px;
        background-color: rgb(12, 2, 104);
        flex-direction: column;

        .fLeft{
            display: flex;
            gap:20px;

            .menuFooter{
                flex: 1;
                gap: 1px;
                display: flex;
                flex-direction: column;
        
                h2{
                    font-size: 18px;
                    font-weight: bold;
                    margin: 0;
                    color:#fff;
                }
                ul{
                    margin: 0px;
                    padding: 0;
                    list-style: none;
        
                    li{
                        color: #d3d2d2;
                        padding: 8px 8px;
                        cursor: pointer;
                        font-size: 13px;
                        text-transform: capitalize;
                    }
                    li:hover{
                        color:#fff;
                        text-decoration: underline;
                    }
                }
                }
                .contactFooter{
                    flex: 1;
                    h2{
                        font-size: 18px;
                        font-weight: bold;
                        margin: 0;
                        color:#fff;
                    }
                    p{
                        line-height: 25px;
                        font-size: 13px;
                        color: #d3d2d2;
                    }
                }
        }
        .fRight{
            display: flex;
            font-size: 11px;
            justify-content: center;
            color: #d3d2d2;
        }

        
    }
    
    
}