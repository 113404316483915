.partners{
    padding: 50px 100px;

    .weTitle{
        margin-bottom: 35px;
        text-align: center;
        font-size: 25px;
        line-height: 40px;
        display: flex;
        flex-direction: column;
        color: rgb(243, 56, 9);
        text-transform: uppercase;

        h1{
            font-family: Anton;
            padding: 0;
            margin: 0;
            font-size: 45px;
            color:rgb(12, 2, 104);
            letter-spacing: 4px;
            text-transform: uppercase;
        }
    }
    .flags{
        padding: 0;
        text-align: center;
        h3{
            font-weight: bolder;
            text-transform: capitalize;
        }
        img{
            width: 100%;
            height: 100px;
            object-fit: cover;
            border: 15px solid #fff;
        }
    }
}