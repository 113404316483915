.post{
    padding: 50px 150px;
    
    h1{
        margin: 0;
        padding: 0;
    }

    .contentfram{
        display: flex;
        gap: 20px;

        .content{
            width: 75%;
            display: flex;
            gap: 20px;
            flex-direction: column;
    
            input{
                padding: 10px;
                border: 1px solid lightgray;
            }
            .edtcontent{
                height: 300px;
                overflow: scroll;
                border: 1px solid lightgray;
    
                .editor{
                    height:100%;
                    border: 0;
                }
            }
        }
        .leftmenu{
            width: 25%;
            display: flex;
            flex-direction: column;
            gap: 20px;
    
            .items{
                border: 1px solid lightgray;
                flex: 1;
                padding: 20px;
                display: flex;
                flex-direction: column;
                gap: 10px;
                justify-content: space-between;
                font-size: 14px;
                color: #555;
    
                h1{
                    color: #000;
                    font-size: 18px;
                }
                .cat{
                    display: flex;
                    align-items: center;
                    font-weight: bold;
                    gap: 2px;
                    color: teal;
                }
                .button{
                    display: flex;
                    justify-content: space-between;
    
                    :first-child{
                        background-color: #180ad8;
                        color:#fff;
                        padding: 5px 10px;
                        border: 0;
                        cursor: pointer;
                        font-weight: bold;
                    }
                    :last-child{
                        background-color: #0ab43d;
                        color:#fff;
                        padding: 5px 10px;
                        border: 0;
                        cursor: pointer;
                        font-weight: bold;
                    }
                }
            }
        }
    }
    .contentable{
        margin-top: 20px;
    }

    
}